const Estructura = () => {
    return (
      <main>
        <div  className="d-flex justify-content-center">
          <h1 className="w-auto">Estructura</h1>
        </div>
        <div className="d-flex justify-content-center  ">
          <img src={require('../img/EstructuraOrganizacional.png')} alt="" className="imagen-about-us" />
        </div>
      </main>
    )
  };
  
  export default Estructura;